import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import fetch from "isomorphic-fetch";

import { Layout, PropertyListings } from "components";

const RecentlySoldProperty = (props) => {
  const { splat, location } = props;
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [page, setPage] = useState(null);
  const [total, setTotal] = useState(null);
  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);

  const loadDataCheck = () => {
    console.log("Reload");
    const page = splat ? splat : 1;
    const soldUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/vaultre/v1/property?page=${page}`;
    fetch(soldUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const total = res.headers.get("x-wp-total");
        const totalPages = res.headers.get("x-wp-totalpages");
        const data = await res.json();
        const rangeStart = 20 * Number(page) - 20;
        const rangeEnd = 20 * Number(page);
        setProperties(data);
        setTotalPages(Number(totalPages));
        setTotal(Number(total));
        setPage(Number(page));
        setStartIndex(rangeStart == 0 ? 1 : rangeStart + 1);
        setEndIndex(rangeEnd > total ? total : rangeEnd);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDataCheck();
  }, [location]);

  return (
    <Layout pageTitle={"Sold Property | Davey Real Estate"} className="dark" layoutType="property-list" sold={true}>
      {loading ? (
        <div className="loading-property">
          <div className="loadingspinner--md" />
        </div>
      ) : (
        <PropertyListings
          location={location}
          data={{
            enableBreadcrumbs: true,
            listTitle: "Sold properties",
            listSubTitle: "Real Estate & Property Sold",
            properties: properties?.nodes,
            numberOfProperties: total,
            propertiesPerPage: 20,
            currentPageNumber: page,
            numberOfPages: totalPages,
            startIndex: startIndex,
            endIndex: endIndex,
            ignoreState: false,
            showFilter: false,
          }}
        />
      )}
    </Layout>
  );
};

const RecentlySoldList = () => {
  return (
    <Router>
      <RecentlySoldProperty path="/recently-sold/" />
      <RecentlySoldProperty path="/recently-sold/:splat" />
    </Router>
  );
};

export default RecentlySoldList;
